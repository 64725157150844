.consent-page-title {
    margin: 5vh 0 2vh
}

.consent-page-checkbox {
    margin-top: 2vh;
}

.consent-page-privacy-link {
    margin-top: 4vh
}