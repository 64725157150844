@import '../../../CommonAssets/Styles/globalStyle';

.landing-app-download-container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    margin: 4vh min(8vw, 80px) 0;
}

.landing-app-download-textarea {
    margin: 0 2vw;
}