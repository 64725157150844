.instructionTitleDownloadAppDialog {
    margin: auto auto 3vh auto;
}

.instructionTextDownloadAppDialog {
    margin: 3vh auto auto auto;
}

.vibeAppIconDownloadAppDialog {
    height: 20vh;
    max-height: 20vh;
    margin: auto
}

.appDownloadImageDownloadDialog {
    height: 6.4vh;
    max-height: 6.4vh;
    margin: auto 1vh;
}

.appDownloadImageAreaDownloadAppDialog {
    margin: auto auto 5vh auto;
}