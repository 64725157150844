@import '../../CommonAssets/Styles/globalStyle';

.disabled {
    opacity: 0.5
}

.headerArea {
    min-height: 9.5vh;
    max-height: 11.5vh;
    margin: 2vh min(8vw, 80px);
}