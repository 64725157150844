@import '../../CommonAssets/Styles/globalStyle';

.buttonStepContent {
    margin: 0;
}

.stepperWorkflow,
.connectorVertical,
.stepperArea {
    padding: 0;
}

.stepperLabelArea {
    justify-content: start;
}

.stepperLabelText,
.stepperLabelTextIcon {
    font-weight: 600;
}

.stepperLabelTextIcon {
    margin-right: 0.6rem;
}

.stepperContent,
.connectorLine {
    margin-top: 0;
    padding-top: 1vh;
    border-left-width: 2px;
    border-left-style: solid;
    border-left-color: get-color(gray80);
}

.connectorLineIcon {
    border-left-width: 3px;
}

.connectorVerticalIcon {
    margin-left: 5.6vh;
}

.connectorCompleted {

    & .connectorLine {
        border-left-color: get-color(teal);
    }
}

.disabled {
    opacity: 0.5;
}
