.instructionWritingInitialSettingApplicationSide {
    margin: auto auto 12vh auto;
}

.titleInitialSettingApplicationSide {
    margin: 2vh 0 11vh 0;
}

.progressInitialSettingApplicationSide {
    font-size: 1.5rem;
    font-weight: 700;
}