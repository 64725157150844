@import '../../../CommonAssets/Styles/globalStyle';

.actionArea {
    display: grid;
    align-items: center;
    grid-template-columns: [running] 1fr;
    grid-template-rows: none;
    justify-items: center;
    align-Self: center;
}

.staticProgress {
    padding: 0.5em;
    border: 5em;
    border-radius: 50%;
    background-color: get-color(white);
    color: get-color(gray90);
    box-shadow: 1px 1px 4px 4px get-color(silver);
    grid-area: running;
}

.playImage {
    grid-area: running;
}

