@import '../../CommonAssets/Styles/globalStyle';

.textLeftAlign {
    margin-left: 0;
    text-align: start
}

.textRightAlign {
    margin-right: 0;
    text-align: end
}

.textTopAlign {
    margin-top: 0;
}

.textBottomAlign {
    margin-bottom: 0;
}

.textTopLeftAlign {
    margin-top: 0;
    margin-left: 0;
    text-align: start
}

.textTopRightAlign {
    margin-top: 0;
    margin-right: 0;
    text-align: end
}

.textBottomLeftAlign {
    margin-bottom: 0;
    margin-left: 0;
    text-align: start
}

.textBottomRightAlign {
    margin-right: 0;
    margin-bottom: 0;
    text-align: end
}

.textNoMargin {
    margin: 0;
}

%comfortaa {
    font-family: get-font(comfortaaregular);
}

%notoSans {
    font-family: get-font(notosansregular);
}

%heading {
    color: get-color(gray);
    font-weight: 700;
}

%body {
    color: get-color(black);
    font-weight: 500;
}

.customSubHeading {
    font-size: 1.1rem;
    font-weight: 700;
}

.red {
    color: get-color(red);
}

.aqua {
    color: get-color(aqua);
}

.orange {
    color: get-color(orange);
}

.teal {
    color: get-color(teal);
}

.comfortaaHeading {
    @extend %heading;
    @extend %comfortaa;
    font-size: 1.5rem;
}

.comfortaaSubHeading {
    @extend %heading;
    @extend %comfortaa;
    font-size: 1.0rem;
}

.comfortaaBody {
    @extend %body;
    @extend %comfortaa;
    font-size: 0.8rem;
}

.notoSansHeading {
    @extend %heading;
    @extend %notoSans;
    font-size: 2rem;
}

.notoSansSubHeading {
    @extend %heading;
    @extend %notoSans;
    font-size: 1.3rem;
}

.notoSansBody16px {
    @extend %body;
    @extend %notoSans;
    font-size: 1rem; //16px = 1rem
}

.notoSansBody {
    @extend %body;
    @extend %notoSans;
    font-size: 0.9rem;
}

.notoSansBodyBig {
    @extend %body;
    @extend %notoSans;
    font-size: 1.1rem;
}