.hiAssemblyTitle {
    margin: 3vh 0 3vh;
}

.hiAssemblyInstructions {
    margin: 1vh 0 3vh;
}

.hiAssemblyFlexContainer {
    flex-grow: 0;
    align-items: center;
}

.hiAssemblyHidden {
    visibility: hidden;
}