@import '../../../CommonAssets/Styles/globalStyle';

.subHeading {
    margin: 7vh 0;
}

.instructions3Margin {
    margin-top: 5vh;
}

.resetSpinnerContainer{
    margin-bottom: 4vh;
}

.loadingText {
    margin-top: 4vh;
}

.percentageText {
    margin: 0;
    color: get-color(orange);
}

.afterResetText {
    margin: 8.6vh;
}