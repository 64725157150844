@import '../../CommonAssets/Styles/globalStyle';

.switchArea {
    margin: 2vh 0;
}

.customSwitch {

    &.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        opacity: 1;
    }
}

.thumb {
    color: get-color(whitegray);
}

.base {

    &.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: get-color(teal);
        opacity: 1
    }
}
