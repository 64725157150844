@import '../../CommonAssets/Styles/globalStyle';

.customSlider {

    &.MuiSlider-root {
        flex: auto;
        color: get-color(gray90);
    }

    &.MuiSlider-vertical .MuiSlider-rail, &.MuiSlider-vertical .MuiSlider-track {
        width: 4px;
    }
}

.sliderButton {
    height: 6.7vh;
    max-height: 6.7vh;
}

.sliderContainer {
    flex-grow: 1;
    align-items: center;
    min-height: 35vh;
    max-height: 50vh;
    padding: 2vh 12px;
}