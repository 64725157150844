@font-face {
    font-family: MetropolisRegular;
    src: local('MetropolisRegular'),
        url(./CommonAssets/Fonts/Metropolis-Regular.otf) format('truetype');
}

@font-face {
    font-family: MetropolisSemiBold;
    src: local('MetropolisSemiBold'),
        url(./CommonAssets/Fonts/Metropolis-SemiBold.otf) format('truetype');
}

@font-face {
    font-family: MetropolisMedium;
    src: local('MetropolisMedium'),
        url(./CommonAssets/Fonts/Metropolis-Medium.otf) format('truetype');
}

@font-face {
    font-family: MetropolisBold;
    src: local('MetropolisBold'),
        url(./CommonAssets/Fonts/Metropolis-Bold.otf) format('truetype');
}

@font-face {
    font-family: ComfortaaBold;
    src: local('ComfortaaBold'),
        url(./CommonAssets/Fonts/Comfortaa-Bold.ttf) format('truetype');
}

@font-face {
    font-family: ComfortaaRegular;
    src: local('ComfortaaRegular'),
        url(./CommonAssets/Fonts/Comfortaa-Regular.ttf) format('truetype');
}

@font-face {
    font-family: NotoSansRegular;
    src: local('NotoSansRegular'),
        url(./CommonAssets/Fonts/NotoSans-Regular.ttf) format('truetype');
}

@font-face {
    font-family: NotoSansBold;
    src: local('NotoSansBold'),
        url(./CommonAssets/Fonts/NotoSans-Bold.ttf) format('truetype');
}

html {
    margin: 0;
    padding: 0;
    font-size: 100%;
}

body {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif, MetropolisRegular, MetropolisMedium,
        MetropolisSemiBold, MetropolisBold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.module {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
}
