@import '../../CommonAssets/Styles/globalStyle';

.colorPrimary {
    background-color: get-color(azure3),
}

.barColorPrimary {
    background-color: get-color(teal),
}

.root {
    height: 1vh;
    border-radius: 20px;
}

.bar {
    border-radius: 20px;
}

.topProgressArea {
    display: flex;
    flex-direction: column;
    margin: 2vh 2vw;
}