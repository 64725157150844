.stepperDots {
    background: transparent
}

.carouselContainer {
    padding: 1vh 0;
}

.carouselDisplay {
    justify-content: space-between;
    margin-bottom: 4vh;
}

.carouselContentContainer {
    flex-direction: unset;
}

.arrowButton {
    padding: 0;
}

.instructionText {
    margin: min(10vw, 100px) min(8vw, 80px);
}

.hidden {
    opacity: 0%;
}